import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';
import { useWorkflowsFolderStorage } from '@/stores/workflowsFolder';

export default function useDeleteWorkflowFolder() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { refetch, setCurrentWorkflowsFolder } = useWorkflowsFolderStorage();
  const { setError } = useErrorStore();
  const { setIsModalOpen, setModalVariant } = useModalsStorage();
  const { mutate, isLoading, isSuccess, reset, isError, status } = useMutation(
    (workflowId: number) =>
      api.delete(API_ENDPOINTS.WORKFLOW_FOLDER_DELETE(workflowId)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workflow-folders');
        toast.success(t('workflow-folders-page.delete-success'));
        setCurrentWorkflowsFolder();
        setIsModalOpen(false);
      },
      onError: (error) => {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          setModalVariant('SERVER_ERROR');
          setError({
            message: t('delete_folder_has_tasks_or_workflows'),
          });
        }
        setCurrentWorkflowsFolder();
        toast.error(t('workflow-folders-page.delete-error'));
      },
      onSettled: () => {
        if (refetch) refetch();
      },
    }
  );

  return {
    isDeletingWorkflowFolder: isLoading,
    deleteWorkflowFolder: mutate,
    deleteWorkflowFolderSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
    status,
  };
}
