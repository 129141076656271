/* eslint-disable */
// @ts-nocheck

import create, { State as ZustandState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { TemplateSectionInterface } from '@/types';

interface TemplateStorageStateInterface extends ZustandState {
  forms: FormTemplateInterface[];
  currentForm: FormTemplateInterface | null;
  builderState: FormTemplateInterface | null;
  isSection: { sectionId: string } | null;
  sectionToEdit: any;
  questionToEdit: any;
  refetch: () => void;
  setRefetch: (refetc: () => void) => void;
  setSectionToEdit: (section: any) => void;
  setQuestionToEdit: (question: any) => void;
  setForms: (forms: FormTemplateInterface[]) => void;
  reorderItems: (updatedBuilderState: FormTemplateInterface) => void;
  updateSection: (updatedSection: TemplateSectionInterface) => void;
  updateQuestion: (updatedQuestion: any) => void;
  setCurrentForm: (currentForm: FormTemplateInterface | null) => void;
  addSection: (section: TemplateSectionInterface) => void;
  setIsSection: (isSection: { sectionId: string } | null) => void;
  addQuestion: (isSection: { sectionId: string } | null, question: any) => void;
  removeQuestion: (questionId: string) => void;
}

export const useFormStorage = create<TemplateStorageStateInterface>(
  persist(
    devtools((set) => ({
      forms: [],
      currentForm: null,
      builderState: null,
      isSection: null,
      sectionToEdit: null,
      refetch: null,
      setRefetch: (refetch) => set({ refetch }),
      setForms: (forms) => set({ forms }),
      setSectionToEdit: (section) => set({ sectionToEdit: section }),
      setQuestionToEdit: (question) => set({ questionToEdit: question }),
      reorderItems: (updatedBuilderState) =>
        set((state) => ({
          ...state,
          builderState: state.builderState && {
            ...state.builderState,
            structure: {
              ...state.builderState.structure,
              schema: updatedBuilderState,
            },
          },
        })),
      updateSection: (updatedSection) =>
        set((state) => ({
          ...state,
          builderState: state.builderState && {
            ...state.builderState,
            structure: {
              ...state.builderState.structure,
              schema: state.builderState.structure.schema.map((section) =>
                section.id === updatedSection.id ? updatedSection : section
              ),
            },
          },
        })),
      updateQuestion: (updatedQuestion) =>
        set((state) => ({
          ...state,
          builderState: state.builderState && {
            ...state.builderState,
            structure: {
              ...state.builderState.structure,
              schema: state.builderState.structure.schema.map((section) => {
                if (section.questions) {
                  section.questions = section.questions.map((question) =>
                    question.id === updatedQuestion.id
                      ? updatedQuestion
                      : question
                  );
                }

                if (section.id === updatedQuestion.id) {
                  return updatedQuestion;
                }

                return section;
              }),
            },
          },
        })),
      setCurrentForm: (currentForm) =>
        set({
          currentForm,
          builderState: {
            ...currentForm,
            structure: {
              ...currentForm?.structure,
              schema: currentForm?.structure?.schema ?? [],
            },
          },
        }),
      addSection: (section) =>
        set((state) => ({
          ...state,
          builderState: state.builderState && {
            ...state.builderState,
            structure: {
              ...state.builderState.structure,
              schema: [...(state.builderState.structure.schema || []), section],
            },
          },
        })),
      setIsSection: (section) => {
        if (section) {
          return set({ isSection: { sectionId: section.sectionId } });
        }
        return set({ isSection: null });
      },
      addQuestion: (isSection, question) => {
        set((state) => {
          if (state.builderState && isSection?.sectionId) {
            return {
              ...state,
              builderState: {
                ...state.builderState,
                id: state.builderState.id,
                structure: {
                  ...state.builderState.structure,
                  schema: state.builderState.structure.schema.map((section) => {
                    if (section.id === isSection.sectionId) {
                      return {
                        ...section,
                        collapsed: false,
                        questions: [...(section.questions || []), question],
                      };
                    }
                    return section;
                  }),
                },
              },
            };
          }
          return {
            ...state,
            builderState: {
              ...state.builderState,
              structure: {
                ...state.builderState?.structure,
                schema: [
                  ...(state.builderState?.structure?.schema || []),
                  question,
                ],
              },
            },
          };
        });
      },
      removeQuestion: (questionId) => {
        set((state) => ({
          ...state,
          builderState: {
            ...state.builderState,
            structure: {
              ...state.builderState.structure,
              schema: state.builderState.structure.schema
                .map((section) => {
                  if (section.id === questionId) {
                    return null;
                  }

                  if (section.questions) {
                    section.questions = section.questions.filter(
                      (question) => question.id !== questionId
                    );
                  }

                  return section;
                })
                .filter((section) => section !== null),
            },
          },
        }));
      },
    })),
    {
      name: 'form-templates',
    }
  )
);
