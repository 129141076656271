import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import { useArchiveEmailTask } from '@/queries/useArchiveEmailTask';
import useCreateEmailTaskReport from '@/queries/useCreateEmailTaskReport';
import useDeleteEmailTask from '@/queries/useDeleteEmailTask';
import { useEmailTasksStorage } from '@/stores/emailTasks';
import { useModalsStorage } from '@/stores/modals';

import { ActionModals } from '@/types';

const useClaimFoldersModals = () => {
  const { deleteEmailTask, isDeletingEmailTask } = useDeleteEmailTask();
  const { archiveEmailTask, isArchiveEmailTask } = useArchiveEmailTask();
  const { setIsModalOpen } = useModalsStorage();
  const { createEmailTaskReport, isLoading } = useCreateEmailTaskReport();
  const {
    currentEmailTask,
    checkedEmailTaskId,
    setCurrentEmailTask,
    setCheckedEmailTaskId,
  } = useEmailTasksStorage();
  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_EMAIL_TASK: {
      children: <CommonModalLabel redText={t('delete_confirm_red_text')} />,
      onConfirm: () => {
        if (currentEmailTask) {
          deleteEmailTask(currentEmailTask.id);
        }
      },
      onClose: () => {
        setIsModalOpen(false);
        setCurrentEmailTask();
      },
      isSubmitting: isDeletingEmailTask,
    },
    ARCHIVE_EMAIL_TASK: {
      children: (
        <CommonModalLabel redText={t('archive_workflow_confirm_red_text')} />
      ),
      onConfirm: () => {
        if (currentEmailTask) {
          archiveEmailTask(currentEmailTask.id);
        }
      },
      onClose: () => {
        setIsModalOpen(false);
        setCurrentEmailTask();
      },
      isSubmitting: isArchiveEmailTask,
    },
    DELETE_ERROR_HAS_INSPECTIONS: {
      children: (
        <label className='mx-auto block max-w-sm text-center text-sm'>
          {t('delete_error_has_inspections')}
        </label>
      ),
    },
    CREATE_REPORT: {
      children: (
        <CommonModalLabel
          redText={t('create_report_confirm_red_text')}
          trailingText={t(
            'emails-page.action-modals.create_report.trailing_text'
          )}
        />
      ),
      onConfirm: () => {
        if (checkedEmailTaskId) {
          createEmailTaskReport(checkedEmailTaskId);
        }
      },
      onClose: () => {
        setCheckedEmailTaskId();
      },
      isSubmitting: isLoading,
    },
    CREATE_REPORT_ERROR: {
      children: (
        <p className='text-center text-sm'>
          <span>
            {t('emails-page.action-modals.create_report_error.init-text')}
          </span>
          <span className='text-inkanTextRed'>
            {t('emails-page.action-modals.create_report_error.red-text')}
          </span>
          <span>
            {t('emails-page.action-modals.create_report_error.trailing-text')}
          </span>
        </p>
      ),
    },
    REPORT_ERROR_EMPTY_TASK: {
      children: (
        <p className='flex flex-col text-center text-sm'>
          <span>
            {t('emails-page.action-modals.report_error_empty_task.init-text')}
          </span>
          <span className='text-inkanTextRed'>
            {t('emails-page.action-modals.report_error_empty_task.end-text')}
          </span>
        </p>
      ),
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useClaimFoldersModals;
