/* eslint-disable @typescript-eslint/no-explicit-any */
import create, { State as ZustandState } from 'zustand';

export enum SideOverPanelTypes {
  SECTION = 'section',
  ELEMENT = 'element',
  FORM_REPORT = 'form-report',
  CREATE_INSPECTION = 'create-inspection',
  EDIT_INSPECTION = 'edit-inspection',
  WORKFLOW_FOLDER_CREATE = 'workflows-folder-create',
  WORKFLOW_FOLDER_EDIT = 'workflows-folder-edit',
  WORKFLOW_EDIT = 'workflow-edit',
  WORKFLOW_CREATE = 'workflow-create',
  TASK_FOLDER = 'tasks-folder',
  TASK = 'task',
  CERTIFICATION = 'certification',
  CLAIM_FOLDER_EDIT = 'claim-folder-edit',
  CLAIM_FOLDER_CREATE = 'claim-folder-create',
  CLAIM_EDIT = 'claim-edit',
  CLAIM_CREATE = 'claim-create',
  EMAIL_TASK_CREATE = 'email-task-create',
  EMAIL_TASK_EDIT = 'email-task-edit',
  EMAIL_EDIT = 'email-edit',
  USER_EDIT = 'user-edit',
  USER_CREATE = 'user-create',
  FORM_EDIT = 'form-edit',
  FORM_CREATE = 'form-create',
}

interface SideOverStorageInterface extends ZustandState {
  currentSideOver?: SideOverPanelTypes;
  setCurrentSideOver: (currentSideOver?: SideOverPanelTypes) => void;
}

export const useSideOverStorage = create<SideOverStorageInterface>((set) => ({
  currentSideOver: undefined,
  setCurrentSideOver: (currentSideOver) =>
    set({
      currentSideOver,
    }),
}));
