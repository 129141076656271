import useTranslation from 'next-translate/useTranslation';

import CommonModalLabel from '@/components/CommonModalLabel/CommonModalLabel';

import { useArchiveClaim } from '@/queries/useArchiveClaim';
import useDeleteClaim from '@/queries/useDeleteClaim';
import { useClaimsStorage } from '@/stores/claims';
import { useModalsStorage } from '@/stores/modals';

import { ActionModals } from '@/types';

const useClaimFoldersModals = () => {
  const { currentClaim, setCurrentClaim } = useClaimsStorage();
  const { deleteClaim, isDeletingClaim } = useDeleteClaim();
  const { archiveClaim, isArchiveClaim } = useArchiveClaim();
  const { setIsModalOpen } = useModalsStorage();

  const { t } = useTranslation('common');

  const modals: ActionModals = {
    DELETE_CLAIM: {
      children: (
        <CommonModalLabel
          leadingText={t('delete_unarchive_claim_confirm_before')}
          redText={t('delete_unarchive_claim_confirm_red_text')}
          trailingText={t('delete_unarchive_claim_confirm_after')}
        />
      ),
      onConfirm: () => {
        if (currentClaim) {
          deleteClaim(currentClaim.id);
        }
      },
      onClose: () => {
        setIsModalOpen(false);
        setCurrentClaim();
      },
      isSubmitting: isDeletingClaim,
    },
    UNABLE_DELETE_CLAIM: {
      children: (
        <label className='flex flex-col items-center text-sm'>
          <span className='mr-2 text-red-500'>
            {t('unable_to_delete_claim_contains_certification_red')}
          </span>
          <span>{t('unable_to_delete_claim_contains_certification')}</span>
        </label>
      ),
      closeText: 'Ok',
    },
    ARCHIVE_CLAIM: {
      children: (
        <label className='flex flex-col items-center text-sm'>
          <p>
            <span>{t('archive_claim.starting')}</span>
            <span className='text-inkanTextRed'>
              {t('archive_claim.red-text')}
            </span>
            <span>{t('archive_claim.trailing')}</span>
          </p>
        </label>
      ),
      onConfirm: () => {
        if (currentClaim) {
          archiveClaim(currentClaim.id);
        }
      },
      onClose: () => {
        setCurrentClaim();
        setIsModalOpen(false);
      },
      isSubmitting: isArchiveClaim,
    },
    CLIENT_ERROR: {
      children: t('client-save-error'),
      closeText: 'Ok',
    },
  };
  return {
    modals,
  };
};

export default useClaimFoldersModals;
