import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useClaimsStorage } from '@/stores/claims';
import { useModalsStorage } from '@/stores/modals';

const archiveClaim = async (claimId: number) => {
  const { data } = await api.post(API_ENDPOINTS.WORKFLOW_ARCHIVE(claimId));

  return data;
};

export function useArchiveClaim() {
  const { t } = useTranslation('common');
  const { refetch, setCurrentClaim } = useClaimsStorage();
  const queryClient = useQueryClient();
  const { setIsModalOpen } = useModalsStorage();

  const { mutate, isLoading, status, isSuccess, reset } = useMutation(
    archiveClaim,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('claims');
        toast.success(t('claims-page.archive-success'));
        refetch && refetch();
      },
      onError: () => {
        toast.error(t('claims-page.archive-error'));
      },
      onSettled: () => {
        setCurrentClaim();
        setIsModalOpen(false);
      },
    }
  );

  return {
    isArchiveClaim: isLoading,
    archiveClaim: mutate,
    archiveClaimStatus: status,
    archiveClaimSuccessfully: isSuccess,
    resetArchive: reset,
  };
}
