export const ERROR_MESSAGES = {
  no_user_credits: {
    title: 'delete-user-credit-error',
    description: 'delete-user-credit-error-description',
  },
  workflow_parent_not_updatable: {
    title: 'workflow_parent_not_updatable',
    description: 'workflow_parent_not_updatable-description',
  },
  repeated_user: {
    title: 'create-user-repeated-error',
    description: 'create-user-repeated-error-description',
  },
  parent_config_cant_be_changed: {
    title: 'parent_config_cant_be_changed',
    description: 'parent_config_cant_be_changed_description',
  },
  user_already_assigned_task: {
    title: 'task-save-assign-error.title',
    description: 'task-save-assign-error.description',
  },
  user_already_assigned_workflow: {
    title: 'workflow-save-assign-error.title',
    description: 'workflow-save-assign-error.description',
  },
  user_already_assigned_claim: {
    title: 'claim-save-assign-error.title',
    description: 'claim-save-assign-error.description',
  },
  user_already_assigned: {
    title: 'user-save-assign-error.title',
    description: 'user-save-assign-error.description',
  },
  folder_name_already_exists: {
    title: 'folder-save-name-already-exists.title',
    description: 'folder-save-name-already-exists.description',
  },
  inspection_has_certifications: {
    description: 'inspection_has_certifications',
  },
  inspection_already_deleted: {
    description: 'inspection_already_deleted',
  },
  inspection_delete_no_permission: {
    description: 'inspection_delete_no_permission',
  },
  inspection_already_submitted: {
    description: 'inspection_already_submitted',
  },
  UNKNOWN: {
    title: 'Unknown error',
    description: '',
  },
};
