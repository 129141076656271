/* eslint-disable @typescript-eslint/no-explicit-any */
import create, { State as ZustandState } from 'zustand';

interface ArchiveStorageStateInterface extends ZustandState {
  archives: any[];
  currentArchive?: any;
  isSubmitting: boolean;
  refetch?: () => void;
  setRefetch: (refetch?: () => void) => void;
  setArchives: (archives: any[]) => void;
  setCurrentArchive: (currentArchive?: any) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const useArchiveStorage = create<ArchiveStorageStateInterface>(
  (set) => ({
    archives: [],
    currentArchive: undefined,
    isSubmitting: false,
    refetch: undefined,
    setRefetch: (refetch) => set({ refetch }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setArchives: (archives) => set({ archives }),
    setCurrentArchive: (currentArchive) => set({ currentArchive }),
  })
);
