import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { QUERIES } from '@/queries';
import { useCertificationStore } from '@/stores/certification';
import { useModalsStorage } from '@/stores/modals';
import { useSideOverStorage } from '@/stores/sideover';
import { trackEvent } from '@/utils/firebase';

import { ActionModals } from '@/types';

const useCertificationModals = () => {
  const { t } = useTranslation('common');
  const { setIsModalOpen } = useModalsStorage();
  const { clearCertification, certification } = useCertificationStore(
    (state) => state
  );
  const queryClient = useQueryClient();
  const { setCurrentSideOver } = useSideOverStorage();
  const [isDeleting, setIsDeleting] = useState(false);

  const onConfirmPress = async () => {
    setIsDeleting(true);
    const res = await api.delete(
      `/certifications/${certification?.identifier}`
    );
    if (res.status === 204) {
      trackEvent('panel_info', { action: 'delete_certification' });
      clearCertification();
      queryClient.invalidateQueries(QUERIES.certifications);
      toast('Certification deleted successfully', { type: 'success' });
      setIsModalOpen(false);
      setCurrentSideOver();
      setIsDeleting(false);
    } else {
      toast('There was an error, please try again.', { type: 'error' });
      setIsDeleting(false);
    }
  };

  const modals: ActionModals = {
    DELETE_CERTIFICATION: {
      children: (
        <div className='flex'>
          <div className=' px-6 text-center'>
            <label className='inline-block'>
              {t('delete_certification_confirm_before')}
            </label>{' '}
            <label className='inline-block text-primary-500'>
              {t('delete_certification_confirm_red_text')}
            </label>{' '}
            <label className='inline-block'>
              {t('delete_certification_confirm_after')}
            </label>
          </div>
        </div>
      ),
      onConfirm: onConfirmPress,
      isSubmitting: isDeleting,
    },
  };
  return {
    modals,
  };
};

export default useCertificationModals;
