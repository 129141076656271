/* eslint-disable @typescript-eslint/no-explicit-any */

import create from 'zustand';

import { PreviewType } from '@/types';

interface WebInspectionDocs {
  uploadedFiles: any;
  isRunningQueue: boolean;
  previews: PreviewType[];
  setUploadedFiles: (uploadedFiles: any) => void;
  setIsRunningQueue: (isRunningQueue: boolean) => void;
  setPreviews: (previews: PreviewType[]) => void;
  removePreview: (idx: number) => void;
  resetDocs: () => void;
}

export const useWebInspectionDocs = create<WebInspectionDocs>((set) => ({
  uploadedFiles: [],
  isRunningQueue: false,
  previews: [],
  setUploadedFiles: (uploadedFiles) => set({ uploadedFiles }),
  setIsRunningQueue: (isRunningQueue) => set({ isRunningQueue }),
  resetDocs: () =>
    set(() => ({
      previews: [],
      uploadedFiles: [],
    })),
  setPreviews: (previews) => set({ previews }),
  removePreview: (idx) =>
    set((state) => {
      const updatedPreviews = state.previews.filter(
        (_, index) => index !== idx
      );
      const updatedUploadedFiles = state.uploadedFiles.filter(
        (_: any, index: number) => index !== idx
      );
      return {
        previews: updatedPreviews,
        uploadedFiles: updatedUploadedFiles,
      };
    }),
}));
