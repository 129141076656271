import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useClaimsStorage } from '@/stores/claims';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';

export default function useDeleteClaim(options?: {
  onSuccess?: () => void;
  onSettled?: () => void;
}) {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setIsModalOpen, setModalVariant } = useModalsStorage();
  const { setCurrentClaim, refetch } = useClaimsStorage();
  const { setError } = useErrorStore();
  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    (claimId: number) => api.delete(API_ENDPOINTS.WORKFLOW_DELETE(claimId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('claims');
        toast.success(t('claims-page.delete-success'));
        setIsModalOpen(false);
        if (refetch) refetch();
        if (options?.onSuccess) options?.onSuccess();
      },
      onError: (error) => {
        setModalVariant('SERVER_ERROR');
        if (axios.isAxiosError(error) && error?.response?.status === 400) {
          setError({
            message: t('claims-page.delete-error-inspections-assigned'),
          });
        } else {
          setError({
            message: t('claims-page.delete-error'),
          });
        }
        setIsModalOpen(true);
      },
      onSettled: async () => {
        setCurrentClaim();
        if (options?.onSettled) options?.onSettled();
      },
    }
  );

  return {
    isDeletingClaim: isLoading,
    deleteClaim: mutate,
    deleteClaimStatus: status,
    deleteClaimSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
