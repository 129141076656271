import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { useFormStorage } from '@/stores/forms';
import { useModalsStorage } from '@/stores/modals';

export default function useDeleteForm() {
  const { t } = useTranslation('common');
  const { refetch } = useFormStorage();
  const queryClient = useQueryClient();
  const { setIsModalOpen } = useModalsStorage();
  const { setCurrentForm } = useFormStorage();
  const { mutate, isLoading, status, isSuccess, isError, reset } = useMutation(
    (formId: string) => api.delete(`/templates/${parseInt(formId)}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('forms');
        toast.success(t('delete_form_success'));
        refetch && refetch();
      },
      onError: () => {
        toast.error(t('delete_form_error'));
      },
      onSettled: () => {
        setCurrentForm(null);
        setIsModalOpen(false);
      },
    }
  );

  return {
    isDeletingForm: isLoading,
    deleteForm: mutate,
    deleteFormStatus: status,
    deleteFormSuccessfully: isSuccess,
    isError,
    resetDeleteForm: reset,
  };
}
