import create, { State as ZustandState } from 'zustand';

import { FolderInterface } from '@/types';

interface WorkflowFolderStorageStateInterface extends ZustandState {
  workflowsFolder: FolderInterface[];
  currentWorkflowsFolder?: FolderInterface;
  selectedWorkflowFolderForAction?: FolderInterface;
  isSubmitting: boolean;
  refetch?: () => void;
  setCurrentWorkflowsFolder: (currentWorkflow?: FolderInterface) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setRefetch: (refetch: () => void) => void;
}

export const useWorkflowsFolderStorage =
  create<WorkflowFolderStorageStateInterface>((set) => ({
    workflowsFolder: [],
    currentWorkflowsFolder: undefined,
    isSubmitting: false,
    selectedWorkflowFolderForAction: undefined,
    refetch: undefined,
    setRefetch: (refetch: () => void) => set({ refetch }),
    setIsSubmitting: (isSubmitting) => set({ isSubmitting }),
    setCurrentWorkflowsFolder: (currentWorkflowsFolder) =>
      set({ currentWorkflowsFolder }),
  }));
