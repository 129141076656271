import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { useArchiveStorage } from '@/stores/archive';
import { useModalsStorage } from '@/stores/modals';

export function useArchiveTask() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  const { mutate, isLoading, status, isSuccess, reset } = useMutation(
    (taskId: string) => api.post(`/tasks/archive/${taskId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast.success(t('archive_task_success'));
      },
      onError: () => {
        toast.error(t('archive_task_error'));
      },
    }
  );

  return {
    isArchiveTask: isLoading,
    archiveTask: mutate,
    archiveTaskStatus: status,
    archiveTaskSuccessfully: isSuccess,
    resetArchiveTask: reset,
  };
}

export function useUnArchiveTask(options?: {
  onSuccess?: () => void;
  onSettled?: () => void;
}) {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setIsModalOpen } = useModalsStorage();
  const { refetch } = useArchiveStorage();

  const { mutate, isLoading, status, isSuccess } = useMutation(
    (taskId: string) => api.post(`/tasks/unarchive/${taskId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tasks');
        toast.success(t('unarchive_task_success'));
        if (refetch) refetch();
        if (options?.onSuccess) options.onSuccess();
      },
      onError: () => {
        toast.error(t('unarchive_task_error'));
      },
      onSettled: () => {
        setIsModalOpen(false);
        if (options?.onSettled) options.onSettled();
      },
    }
  );

  return {
    isArchiveTask: isLoading,
    unarchiveTask: mutate,
    unarchiveTaskStatus: status,
    unarchiveTaskSuccessfully: isSuccess,
  };
}
