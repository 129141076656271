/* eslint-disable @typescript-eslint/no-explicit-any */
import create, { State as ZustandState } from 'zustand';

import { ActionModalVariants } from '@/types';

interface ModalsStorageInterface extends ZustandState {
  isModalOpen: boolean;
  modalVariant: ActionModalVariants;
  onModalConfirm: null | (() => void);
  setIsModalOpen: (isModalOpen: boolean) => void;
  setModalVariant: (modalVariant: ActionModalVariants) => void;
  setOnModalConfirm: (onModalConfirm: () => void) => void;
}

export const useModalsStorage = create<ModalsStorageInterface>((set) => ({
  isModalOpen: false,
  modalVariant: 'CERTIFICATION_ERROR',
  onModalConfirm: null,
  setIsModalOpen: (isModalOpen) => set({ isModalOpen }),
  setModalVariant: (modalVariant) => set({ modalVariant }),
  setOnModalConfirm: (onModalConfirm) => set({ onModalConfirm }),
}));
