import { DeleteConfirmationModal } from '@/components/ui/Modal/DeleteConfirmationModal';

import { useUnArchiveTask } from '@/queries/useArchiveTask';
import useDeleteClaim from '@/queries/useDeleteClaim';
import useDeleteTaskFolder from '@/queries/useDeleteEmailTask';
import useDeleteWorkflow from '@/queries/useDeleteWorkflow';
import { useArchiveStorage } from '@/stores/archive';
import { useTasksStorage } from '@/stores/tasks';

import { ActionModals } from '@/types';

const useArchiveModals = () => {
  const { refetch } = useArchiveStorage();
  const { currentTask, setCurrentTask } = useTasksStorage();
  const { deleteWorkflow, isDeletingWorkflow } = useDeleteWorkflow({
    onSuccess: refetch,
    onSettled: () => {
      setCurrentTask();
    },
  });
  const { deleteClaim, isDeletingClaim } = useDeleteClaim({
    onSuccess: refetch,
    onSettled: () => {
      setCurrentTask();
    },
  });
  const { deleteEmailTask, isDeletingEmailTask } = useDeleteTaskFolder({
    onSuccess: refetch,
    onSettled: () => {
      setCurrentTask();
    },
  });

  const { unarchiveTask, isArchiveTask } = useUnArchiveTask();

  const modals: ActionModals = {
    DELETE_TASK: {
      children: <DeleteConfirmationModal actionSelect={'ModalDelete'} />,
      onConfirm: () => {
        if (!currentTask) return;
        const { id, type } = currentTask;
        if (type === 'workflow') deleteWorkflow(Number(id));
        if (type === 'claim') deleteClaim(Number(id));
        if (type === 'email') deleteEmailTask(Number(id));
      },
      isSubmitting:
        isDeletingWorkflow || isDeletingClaim || isDeletingEmailTask,
    },
    UNARCHIVE_TASK: {
      children: <DeleteConfirmationModal actionSelect={'ModalUnarchive'} />,
      onConfirm: () => {
        unarchiveTask(currentTask?.id as string);
      },
      isSubmitting: isArchiveTask,
    },
  };
  return {
    modals,
  };
};

export default useArchiveModals;
