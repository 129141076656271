/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import create, { State as ZustandState } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { buildDefaultStepState } from '@/utils/webForm';

import {
  ClaimInterface,
  Inspection,
  TemplateInterface,
  WorkflowInterface,
} from '@/types';

interface WebInspectionsStorageStateInterface extends ZustandState {
  // Values
  inspection?: Inspection;
  task: WorkflowInterface | ClaimInterface | undefined;
  stepsState: any;
  currentStep: number;
  form: any;
  normalizedForm: any;
  draftSchema: any;
  completed: boolean;
  stopNavigation: boolean;
  // Setters
  setStopNavigation: (stopNavigation: boolean) => void;
  setDraftSchema: (draftSchema?: any) => void;
  setInspection: (inspection?: Inspection) => void;
  setCurrentStepSection: (stepIdx: number, currentSection: number) => void;
  setCurrentStep: (currentStep: number) => void;
  initStepsState: (form?: TemplateInterface) => void;
  setTask: (task?: ClaimInterface | undefined) => void;
  setForm: (form?: any) => void;
  setNormalizedForm: (normalizedForm?: any) => void;
  resetStore: () => void;
  setCompleted: (completed: boolean) => void;
}

const defaultValues = {
  task: undefined,
  inspection: undefined,
  currentStep: 1,
  stepsState: [],
  normalizedForm: undefined,
  form: undefined,
  draftSchema: undefined,
  completed: false,
  stopNavigation: false,
};

export const useWebInspectionStorage =
  create<WebInspectionsStorageStateInterface>(
    persist(
      devtools((set) => ({
        ...defaultValues,
        setStopNavigation: (stopNavigation) => set({ stopNavigation }),
        setNormalizedForm: (normalizedForm) => set({ normalizedForm }),
        setForm: (form) => set({ form }),
        setDraftSchema: (draftSchema) => set({ draftSchema }),
        setTask: (task) => set({ task }),
        setCurrentStep: (currentStep) => set({ currentStep }),
        setInspection: (inspection) => set({ inspection }),
        initStepsState: (form) =>
          set((state) => ({
            stepsState:
              form &&
              state.inspection &&
              buildDefaultStepState(state.inspection),
          })),
        setCurrentStepSection: (stepIdx, currentSection) =>
          set((state) => ({
            stepsState: state.stepsState?.map((stepState: any, idx: number) =>
              stepIdx === idx
                ? {
                    ...stepState,
                    currentSection: currentSection,
                  }
                : {
                    ...stepState,
                  }
            ),
          })),
        resetStore: () => {
          set(() => ({
            ...defaultValues,
          }));
        },
        setCompleted: (completed) => set({ completed }),
      })),
      {
        name: 'web-form',
      }
    )
  );
