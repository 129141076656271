import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useModalsStorage } from '@/stores/modals';

const createReport = async (id: number) => {
  const response = await api.post(API_ENDPOINTS.REPORTS_GENERATE(), {
    inspection_id: id,
  });
  return response;
};

export default function useCreateReport() {
  const { t } = useTranslation('common');
  const { setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, error, isSuccess, isError, reset } = useMutation(
    createReport,
    {
      onSuccess: () => {
        toast.success(
          t('form-preview.create-report-form.messages.report-create-success')
        );
      },
      onError: () => {
        toast.error(
          t('form-preview.create-report-form.messages.report-create-error')
        );
      },
      onSettled: () => {
        setIsModalOpen(false);
      },
    }
  );

  return {
    isSavingReport: isLoading,
    error,
    createReport: mutate,
    isSuccess,
    isError,
    reset,
  };
}
