// TODO: This component should be refactored for more complex usage.
// !: Also should be reviewd a translation ussage for this component and also modals itself.
// ? More information check file "common.json"

import useTranslation from 'next-translate/useTranslation';

interface DeleteConfirmationModalProps {
  actionSelect: string;
}

export enum ModalType {
  Delete = 'ModalDelete',
  Archive = 'ModalArchive',
  Unarchive = 'ModalUnArchive',
}

type ConfirmationModalType = {
  actionSelect: string;
  beforeText: string;
  afterText: string;
  deleteRedText?: string;
  archiveRedText?: string;
};

export const DeleteConfirmationModal = ({
  actionSelect,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation('common');
  return (
    <div className='flex justify-center'>
      <div className='px-6 text-center'>
        <label className='inline-block text-sm'>
          {t('delete_form_confirm_before')}
        </label>
        <label className='ml-1 inline-block text-sm text-primary-500'>
          {actionSelect === 'ModalDelete' && t('delete_archive')}
          {actionSelect === 'ModalUnarchive' && t('unarchive_confirm_red_text')}
        </label>
        <label className='inline-block text-sm'>{'?'}</label>
        {actionSelect === 'ModalDelete' && (
          <label className='inline-block text-sm'>
            {t('delete_archive_task_confirm_after')}
          </label>
        )}
      </div>
    </div>
  );
};

export const ConfirmationModalContent = ({
  actionSelect,
  beforeText,
  afterText,
  deleteRedText,
  archiveRedText,
}: ConfirmationModalType) => {
  return (
    <div className='flex justify-center'>
      <div className='mb-5 px-6 text-center'>
        <p className='inline-block text-sm'>{beforeText}</p>
        <p className='mx-1 inline-block text-sm text-primary-500'>
          {actionSelect === 'ModalDelete' ? deleteRedText : archiveRedText}
        </p>
        <p className='inline-block text-sm'>{afterText}</p>
      </div>
    </div>
  );
};
