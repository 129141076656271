import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useErrorStore } from '@/stores/error';
import { useModalsStorage } from '@/stores/modals';
import { useWorkflowsStorage } from '@/stores/workflows';

export default function useDeleteWorkflow(options?: {
  onSuccess?: () => void;
  onSettled?: () => void;
}) {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { setIsModalOpen, setModalVariant } = useModalsStorage();
  const { setError } = useErrorStore();
  const { setCurrentWorkflow, refetch } = useWorkflowsStorage();

  const { mutate, isLoading, status, isSuccess, reset, isError } = useMutation(
    ['delete-workflow'],
    (workflowId: number) =>
      api.delete(API_ENDPOINTS.WORKFLOW_DELETE(workflowId)),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('workflows');
        toast.success(t('workflows-page.delete-success'));
        if (options?.onSuccess) options?.onSuccess();
        if (refetch) refetch();
        setIsModalOpen(false);
      },
      onError: (error) => {
        setModalVariant('SERVER_ERROR');
        if (axios.isAxiosError(error) && error?.response?.status === 400) {
          setError({
            message: t('workflows-page.delete-error-inspections-assigned'),
          });
        } else {
          setError({
            message: t('workflows-page.delete-error'),
          });
        }
        setIsModalOpen(true);
      },
      onSettled: () => {
        setCurrentWorkflow();
        if (options?.onSettled) options?.onSettled();
      },
    }
  );

  return {
    isDeletingWorkflow: isLoading,
    deleteWorkflow: mutate,
    deleteWorkflowStatus: status,
    deleteWorkflowSuccessfully: isSuccess,
    resetDelete: reset,
    isError: isError,
  };
}
