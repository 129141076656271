import create from 'zustand';

interface Error {
  title?: string;
  message: string;
}

interface ErrorStoreProps {
  error?: Error;
  errors?: Error[];
  setError: (error: Error) => void;
  setErrors: (errors: Error[] | []) => void;
}

export const useErrorStore = create<ErrorStoreProps>((set) => ({
  error: undefined,
  errors: [],
  setError: (error) => set({ error }),
  setErrors: (errors) => set({ errors }),
}));
