import create from 'zustand';

import api from '@/lib/api';

import { CertificationDashboard } from '@/types';

interface CertificationStoreProps {
  certification?: CertificationDashboard;
  refetch?: () => void;
  setRefetch: (refetch: () => void) => void;
  setCertification: (certification?: CertificationDashboard) => void;
  clearCertification: () => void;
  selectedCertificationIds: string[] | [];
  setSelectedCertificationIds: (certificationIds: string[] | []) => void;
}

const useStore = create<CertificationStoreProps>((set) => ({
  certification: undefined,
  selectedCertificationIds: [],
  refetch: undefined,
  setRefetch: (refetch) => set({ refetch }),
  setSelectedCertificationIds: (selectedCertificationIds) =>
    set({ selectedCertificationIds }),
  setCertification: (certification) => {
    if (!certification) return;
    api
      .post(`/certifications/${certification.identifier}/visited`, {})
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log('err');
      });
    return set({ certification });
  },
  clearCertification: () => set({ certification: undefined }),
}));

export const useCertificationStore = useStore;
