import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { API_ENDPOINTS } from '@/constants/ApiConfig';
import { useCertificationStore } from '@/stores/certification';
import { useModalsStorage } from '@/stores/modals';

const mergeReports = async (reports: string[]) => {
  const response = await api.post(
    API_ENDPOINTS.MERGE_REPORTS(),
    {
      reports,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  return response;
};

function downloadPDF(data: string, mimeType: string) {
  const blob = new Blob([data], { type: mimeType });
  const blobURL = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobURL;
  link.textContent = 'Download PDF';
  link.download = 'merged_report.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default function useMergeReports() {
  const { t } = useTranslation('common');
  const { setSelectedCertificationIds } = useCertificationStore();
  const { setIsModalOpen } = useModalsStorage();

  const { mutate, isLoading, error, isSuccess, isError, reset } = useMutation(
    mergeReports,
    {
      onSuccess: (res) => {
        toast.success(t('reports-page.merge-reports-success'));
        downloadPDF(res.data, res.headers['content-type']);

        setSelectedCertificationIds([]);
        setIsModalOpen(false);
      },
      onError: () => {
        toast.error(t('reports-page.merge-reports-error'));
      },
    }
  );

  return {
    isMergingReports: isLoading,
    error,
    mergeReports: mutate,
    isSuccess,
    isError,
    reset,
  };
}
