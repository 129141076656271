import axios from 'axios';
import useTranslation from 'next-translate/useTranslation';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import api from '@/lib/api';

import { useEmailTasksStorage } from '@/stores/emailTasks';
import { useModalsStorage } from '@/stores/modals';

const createEmailTaskReport = async (emailTaskId: number) => {
  try {
    if (emailTaskId) {
      return await api.post('/reports/email_task/generate', {
        task_id: emailTaskId,
      });
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error?.response?.status === 400) {
      throw {
        title: 'Report Error',
        status: error.response.status,
      };
    }
  }
};

const useCreateEmailTaskReport = () => {
  const { t } = useTranslation('common');
  const { setCheckedEmailTaskId } = useEmailTasksStorage();
  const { setModalVariant, setIsModalOpen } = useModalsStorage();
  const { mutate, isLoading, error, reset, isSuccess } = useMutation(
    createEmailTaskReport,
    {
      onSuccess: () => {
        toast.success(t('report-create-success'));
        setIsModalOpen(false);
      },
      onError: (error: { status: number }) => {
        if (error.status === 400) {
          setModalVariant('REPORT_ERROR_EMPTY_TASK');
          setIsModalOpen(true);
        }
        if (error.status === 402) {
          setModalVariant('CREATE_REPORT_ERROR');
          setIsModalOpen(true);
        }
        toast.error(t('report-create-error'));
      },
      onSettled: () => {
        setCheckedEmailTaskId();
      },
      mutationKey: ['report-create'],
    }
  );
  return {
    createEmailTaskReport: mutate,
    createReportEmailTaskSuccesfully: isSuccess,
    error,
    resetCreateReport: reset,
    isLoading,
  };
};

export default useCreateEmailTaskReport;
